export class WhitelabelLanguageDetector {
    detectLanguage(): string {
        const availableLanguages = ['en', 'ja'];
        const uri = new URL(window.location.href);
        const path = uri.pathname;
        const pathParts = path.split('/');
        if (pathParts.length > 1) {
            const languagePrefix = pathParts[1];
            if (availableLanguages.includes(languagePrefix)) {
                return `/${languagePrefix}`;
            }
        }
        return '';
    }
}
